import React, { useState } from 'react';
import './LegalAuthorization.css';

const LegalAuthorization = ({ handleAgreed }) => {
    const [isAgreed, setAgreed] = useState(false);

    const btnClicked = () => {
        handleAgreed(!isAgreed);
        setAgreed(!isAgreed);
    }

    return (
        <div className='module-legal-auth'>
            <label htmlFor="agreement-checkbox">
                <input type="checkbox"
                       id='agreement-checkbox'
                       checked={isAgreed}
                       onChange={btnClicked}/>
                    <b>J'ai lu et j'accepte la <a target="_blank" rel="noreferrer" href='https://assas.net/confidentialite'>
                        politique de confidentialité</a> d'Assas.net</b>
            </label>
        </div>
    )
}

export default LegalAuthorization;
