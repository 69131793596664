const formFilleul = [
    {
        "id": "ajhbzzlhkbz",
        "api_field": "firstName",
        "question": "Quel est ton prénom ?",
        "type": "text",
        "answer": '',
        "error": false,
        "required": true,
        "errorMessage": 'Veuillez entrer un nom valide.',
        "regex": '^[a-zA-ZÀ-ú\\-\\s\\\']*'
    },
    {
        "id": "bpunpethzik",
        "api_field": "name",
        "question": "Et ton nom de famille ?",
        "type": "text",
        "answer": '',
        "error": false,
        "required": true,
        "errorMessage": 'Veuillez entrer un nom valide.',
        "regex": '^[a-zA-ZÀ-ú\\-\\s\\\']*'
    },
    {
        "id": "cstexncazkn",
        "api_field": "email",
        "question": "Il nous faudra également une adresse email...",
        "type": "email",
        "answer": '',
        "error": false,
        "required": true,
        "errorMessage": 'Veuillez entrer un e-mail valide.',
        "regex": '\\S+@\\S+\\.\\S+'
    },
    {
        "id": "dsfqyiqlmwe",
        "api_field": "phone",
        "question": "Et un numéro de téléphone !",
        "type": "text",
        "answer": '',
        "error": false,
        "required": true,
        "errorMessage": 'Veuillez entrer un numéro de téléphone valide.',
        "regex": '^\\+?(?:[0-9] ?){6,14}[0-9]$'
    },
    {
        "id": "dtifnydnfl",
        "api_field": "facebook",
        "question": "Si tu utilises Instagram, indique ton pseudo ci-dessous !",
        "type": "text",
        "answer": '',
        "error": false,
        "required": false,
        "errorMessage": 'Veuillez saisir une entrée valide.',
        "regex": '^[a-zA-ZÀ-ú\\-\\s\\\']*'
    },
    {
        "id": "eukkqpfbpoc",
        "api_field": "center",
        "question": "On peut commencer ! Paris ou Melun (cette année) ?",
        "type": "dropdown",
        "required": true,
        "answersPossible": [
            {
                "label": "Paris",
                "value": "Paris"
            },
            {
                "label": "Melun",
                "value": "Melun"
            }
        ],
        "answer": '',
        "errorMessage": 'Veuillez sélectionner une entrée valide.',
        "error": false
    },
    {
        "id": "fxysqkktpjx",
        "api_field": "pathway",
        "question": "En quelle filière ?",
        "type": "dropdown",
        "answersPossible": [
            {
                "label": "Droit",
                "value": "Droit"
            },
            {
                "label": "Eco-G",
                "value": "Eco-G"
            },
            {
                "label": "AES",
                "value": "AES"
            },
            {
                "label": "Management",
                "value": "Management"
            },
            {
                "label": "Sciences po",
                "value": "Sciences po",
            },
            {
                "label": "Info-Com",
                "value": "Info-Com",
            }
        ],
        "answer": '',
        "error": false,
        "required": true,
        "errorMessage": 'Veuillez sélectionner une entrée valide.'
    },
    {
        "id": "gahicfeijbb",
        "api_field": "year",
        "question": "Et en quelle année ?",
        "type": "dropdown",
        "required": true,
        "answersPossible": [
            {
                "label": "Licence 1",
                "value": "Licence 1"
            },
            {
                "label": "Licence 2",
                "value": "Licence 2"
            },
            {
                "label": "Licence 3",
                "value": "Licence 3"
            },
            {
                "label": "Master 1",
                "value": "Master 1"
            },
            {
                "label": "Master 2",
                "value": "Master 2"
            }
        ],
        "answer": '',
        "error": false,
        "errorMessage": 'Veuillez sélectionner une entrée valide.',
    },
    {
        "id": "hifhrnushfn",
        "api_field": "equipeL1",
        "question": "Et ta L1, quelle équipe ?",
        "type": "dropdown",
        "answersPossible": [
            {
                "label": "Equipe 1",
                "value": "Equipe 1"
            },
            {
                "label": "Equipe 2",
                "value": "Equipe 2"
            },
            {
                "label": "Equipe 3",
                "value": "Equipe 3"
            },
            {
                "label": "Pas d'équipe (pas en droit / pas à Paris)",
                "value": "Aucune"
            },

        ],
        "answer": '',
        "error": false,
        "required": true,
        "errorMessage": 'Veuillez sélectionner une entrée valide.'
    },
    {
        "id": "nldspejflsi",
        "api_field": "comments",
        "question": "Parfait, c'est dans la boîte ! As-tu quelque chose de particulier dont tu souhaiterais nous faire part, pour nous aider à te trouver le.a parrain/marraine idéal.e (étudiant étranger, en situation de handicap...) ? Si non, laisse le champ vide.",
        "type": "textarea",
        "required": false,
        "answer": '',
        "error": false,
        "errorMessage": 'Veuillez sélectionner une entrée valide.'
    }
];

export default formFilleul
