import React, { useContext } from 'react';
import { Container } from "react-bootstrap";
import './App.css';
import ChoixForm from './components/ChoixForm';
import FormPage from './pages/FormPage';

import Header from "./components/Header";
import ErrorPage from "./pages/ErrorPage";
import ThanksPage from "./pages/ThanksPage";
import ClosedPage from "./pages/ClosedPage";
import { FormContext } from './utils/context';


const App = () => {
    const { type, formInError, formUploaded } = useContext(FormContext);

    return (
        <div id="app-content">
            <Container fluid className="App">
                <Header />
                { process.env.REACT_APP_PARRAINAGE_OUVERT ? (
                    type === '' ? (<ChoixForm />) : (
                    formInError ? <ErrorPage /> : (
                    formUploaded ? <ThanksPage /> : 
                    <FormPage />))
                ) : <ClosedPage /> }
            </Container>
        </div>
    )
}

export default App;
