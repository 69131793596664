import React, { useState, useContext } from 'react';
import TypeForm from 'react-typeform';
import Type from '../components/Type'
import { ProgressBar, Row, Col, Card } from "react-bootstrap";
import $ from "jquery";
import { FormContext } from '../utils/context';
import './FormPage.css';
import { uploadForm } from '../utils/api';

const FormPage = () => {
    const [ currentPage, setCurrentPage ] = useState(0);
    const { form, setForm, type, setFormInError, setFormUploaded } = useContext(FormContext);

    const submitForm = async () => {
        const formAsObject = {};
        form.forEach(element => {
            element.answer = 
                (element.api_field === 'maxFilleuls' || element.api_field === 'maxParrains') 
                ? parseInt(element.answer)
                : element.answer;

            formAsObject[element.api_field] = element.answer;
        });

        const result = await uploadForm(type, JSON.stringify(formAsObject));

        result === 201 ? setFormUploaded(true) : setFormInError(true);
    }

    const updateForm = (id, data) => {
        setForm(form.map((row) => {
            if(row.id === id) {
                return {
                    ...row,
                    answer: data
                }
            }
            return row;
        }));
    }

    function onNextClick(e) {
        const obj = form[currentPage+1];
        if(obj.required && (obj.error || obj.answer === '')) {
            $(".next-btn").prop("disabled", true);
        }

        setCurrentPage(currentPage+1);
    }

     /**
     * Au clic sur Précédent, on réactive le bouton Suivant, même s'il était désactivé (le champ précédent est
     * forcément valide). Enfin, on met à jour l'index dans le state.
     */
      function onPreviousClick (e) {
        $(".next-btn").prop("disabled", false);
        setCurrentPage(currentPage-1);
    };

    return (
        <Row>
            <Col>
                <div className="progress-container">
                    {currentPage+1} / {form.length}
                    <ProgressBar animated variant="success" now={(currentPage+1) / form.length * 100} />
                </div>
                <TypeForm
                    className="form-container"
                    nextBtnText="Suivant"
                    nextBtnClass="next-btn btn btn-primary btn-sm"
                    backBtnText="Précédent"
                    backBtnClass="back-btn btn btn-link btn-sm"
                    showReviewView={false}
                    onSubmit={submitForm}
                    submitBtnText={"Enregistrer"}
                    submitBtnClass="submit-btn btn btn-primary btn-sm"
                    nextBtnOnClick={(e) => onNextClick(e)}
                    backBtnOnClick={(e) => onPreviousClick(e)}
                >
                    {form.map(row => (
                        <Card key={row.id} className="module-choix-card">
                            <Type {...row} updateForm={updateForm} />    
                        </Card>
                    ))}
                </TypeForm>
            </Col>
        </Row>
    )
}

export default FormPage;
