import React, { createContext, useState } from "react";
import { PARRAINS } from "../constants";
import formParrain from "../form_parrain";
import formFilleul from "../form_filleul";

export const FormContext = createContext();

export const FormProvider = ({children}) => {
    const [ form, setForm ] = useState('');
    const [ type, setType ] = useState('');
    const [ formInError, setFormInError ] = useState(false);
    const [ formUploaded, setFormUploaded ] = useState(false);

    const selectForm = (newType) => {
        setType(newType);
        setForm(newType === PARRAINS ? formParrain : formFilleul);
    }

    const setFieldError = (id, error) => {
        let newForm = form;
        newForm.map((row) => {
            if(row.id === id) {
                row.error = error;
            }
            return row;
        });

        setForm(newForm);
    }

    return (
        <FormContext.Provider value={{ form, setForm, selectForm, type, setType, setFieldError, formInError, setFormInError, formUploaded, setFormUploaded }}>
            {children}
        </FormContext.Provider>
    )
}