import React, {useState, useContext} from 'react';
import {Row, Col, Card, } from 'react-bootstrap'
import LegalAuthorization from './LegalAuthorization'
import './ChoixForm.css';
import { FILLEULS, PARRAINS } from '../utils/constants';
import { FormContext } from '../utils/context';

const ChoixForm = () => {
    const [agreement, setAgreement] = useState(false);

    return (
        <div className="choix-form">
            <Row className="mb-xs-20 mb-lg">
                <h1 className="title w-100 text-center">Voulez-vous devenir...</h1>
                <LegalAuthorization handleAgreed={() => { setAgreement(!agreement) }} />
            </Row>
            <Row className="links justify-content-md-center">
                <ChoixFormModule agreement={agreement} type={PARRAINS} />
                <ChoixFormModule agreement={agreement} type={FILLEULS} />
            </Row>
        </div>
    )
}

const ChoixFormModule = ({ type, agreement }) => {
    const { selectForm } = useContext(FormContext);

    const description = type === FILLEULS ? (`Vous entrez en première année (ou à Assas), dans le
    monde merveilleux et effrayant qu'est l'enseignement supérieur, et pire encore :
    dans cette filière macabre qu'est le droit (ou l'éco, ou l'AES, ou
    l'info-com, ou le management... vous avez saisi).<br/><br/>
    Remplissez notre formulaire en moins de 2 minutes, et hop ! Nous vous mettrons en
    relation avec un.e parrain/marraine qui vous correspond, étudiant.e de Paris-II qui pourra vous
    faire profiter de son expérience.`)
    : (`Étudiant.e endurci.e d'Assas, vous avez
    vaillamment franchi les obstacles qui se sont dressés devant vous :
    les TD, les partiels, la queue au bar à salade...
    <br />
    <br />
    Vous souhaitez faire profiter une jeune pousse de votre expérience ? Aider un.e
    nouveau.elle venu.e à s'intégrer à l'Université, à comprendre les arcanes d'Assas ?
    Pour cela, il vous suffit de remplir notre formulaire, et nous vous attribuerons
    un.e filleul.e idéal.e !`);
    
    return (
        <Col className="link mbt-3 col-12 col-lg-5">
            <Card className="module-choix-card">
                <Card.Title className="title-card"><b>{ type === FILLEULS ? 'Filleul' : 'Parrain' } ?</b></Card.Title>
                <Card.Text className="card-content-custom" dangerouslySetInnerHTML={{__html: description}} />
                { agreement ? (
                    <Card.Link
                        className="card-link-custom"
                        href="#"
                        onClick={() => { selectForm(type) }}><b>Devenir {type.slice(0, type.length-1)}</b>
                    </Card.Link>) 
                : (
                    <div className="card-link-custom">
                        <b>Devenir { type.slice(0, type.length-1) }</b><br/>
                        <span style={{'fontSize': '0.7em'}}>
                            (veuillez accepter la politique de confidentalité pour continuer)
                        </span>
                    </div>    
                )}
            </Card>
        </Col>
    )
}

export default ChoixForm;
