import React from 'react'
import './Header.css';

const Header = (props) => {
    const type = props.formType !== undefined && props.formType.slice(0, props.formType.length-1);

    return (
        <header>
            {/*<LoadingBar className='loading-bar'/>*/}
            <div className="header-no-loading page-head">
                {props.formType !== undefined && <h1>Devenir {type}</h1>}
                <img src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1587472826785x696005924292845800%2FLogo%2520sans%2520fond-min.png?w=48&h=48&auto=compress&dpr=3&fit=crop&q=75"
                    className="logo"
                    alt="Logo Assas.net"
                />
            </div>
        </header>
    )
};

export default Header;
