const ThanksPage = () => {
    return (
        <div className='page thanks' style={{marginTop: '50px'}}>
            <h1>Merci !</h1>
            <p>
                Tout semble en ordre, il ne te reste plus qu'à patienter : nous reviendrons
                vers toi dans quelques jours avec le nom de ton binôme !
            </p>
        </div>
    )
}

export default ThanksPage;