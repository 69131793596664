import axios from 'axios';

export const uploadForm = async (type, form) => {
    return await axios.post(process.env.REACT_APP_API_URL + type, 
        form, 
        {headers : { 'Content-Type' : 'application/json' }})
      .then(res => {
            return res.status;
      }).catch((err) => {
          return err;
      })
}
