
const ErrorPage = () => {
    return (
        <div className='page error-page'>
            <h1>Erreur</h1>
            <p>
                Une erreur est survenue avec l'envoi de votre formulaire.<br/>
                Veuillez contacter <a href="mailto:bureau@assas.net">bureau@assas.net</a> ou nous
                envoyer un message sur <a href="https://facebook.com/OfficielAssas.net/">Facebook</a> ou <a href="https://www.instagram.com/assasnet">Instagram</a>.
            </p>
        </div>
    )
};

export default ErrorPage;
