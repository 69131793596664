import React, { useState, useContext, useEffect, useRef } from "react";
import {Row, Col, Form} from 'react-bootstrap'
import $ from 'jquery';
import './Type.css'
import { FormContext } from "../utils/context";

const Type = (props) => {
    const [answer, setAnswer] = useState('');
    const [error, setError] = useState(false);
    const { updateForm, id, required } = props;
    const { setFieldError } = useContext(FormContext);

    useEffect(() => {
        required && $(".next-btn").prop("disabled", true);
    }, []);

    const validateInput = (input) => {
        let isError = false;

        // On vérifie que le champ n'est pas vide
        if(input === '' && props.required) {
            isError = true;
        } else {
            // On valide l'input pour tous les types d'input (text, multiple...)
            switch (props.type) {
                case 'text':
                case 'email':
                    const regex = new RegExp(props.regex);
                    if (!regex.test(input)) {
                        isError = true;
                    }
                    break;
                case 'dropdown':
                    if (props.answersPossible.filter(ans => ans.value === input).length !== 1) {
                        isError = true;
                    }
                    break;
                default:
                    break;
            }
        }
        // On active/désactive le bouton Suivant selon la validation. Et on update le state global du form.
        $(".next-btn").prop("disabled", isError);
        setFieldError(id, isError);
        setError(isError);

        // Et on retourne l'inverse d'error (s'il y a une erreur (error === true), on veut que cette fonction
        // (validateInput) renvoie 'false'
        return !isError;
    }

    const handleAnswerChange = (input) => {
        if(validateInput(input)) {
            setAnswer(input);
            $(".next-btn").prop("disabled", false);
            $(":submit").prop("disabled", false);

            // Update the form
            updateForm(id, input);

            // If the question is a dropdown, go to next question as soon as an answer is selected
            props.type === 'dropdown' && $('.next-btn').trigger('click');
        }
    }

    // According to the questionType that is passed to the Component, render a specific "SubType"
    const renderingSwitch = (questionType) => {
        switch(questionType) {
            case 'text':
            case 'email':
                return (<TextInputType id={props.id} answer={props.answer} type={props.questionType}
                    onAnswerChange={handleAnswerChange} />);
            case 'dropdown':
                return (<MultipleChoicesType answersPossible={props.answersPossible} id={id}
                    onAnswerChange={handleAnswerChange}/>);
            case 'textarea':
                return (<TextAreaType answer={props.answer} type={props.questionType}
                    onAnswerChange={handleAnswerChange}/>);
            default:
                return (<></>);
        }
    }

    return (
        <Row className="question-module">
            <Col className="column">
                <Form className="bloc-component" onSubmit={e => e.preventDefault()}>
                    <Form.Group className="question-container">
                        <Form.Label className="question">
                            {props.question}
                            {props.required && <Form.Text className="text-muted text-mandatory"> *Obligatoire</Form.Text>}
                        </Form.Label>
                        {renderingSwitch(props.type)}
                        {
                            (error || !answer) && (<Form.Text className="error">{props.errorMessage}</Form.Text>)
                        }
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    );
}

const MultipleChoicesType = (props) => {
    const [answer, setAnswer] = useState('');
    const { onAnswerChange, answersPossible, id } = props;

    useEffect(() => {
        onAnswerChange(answer);
    }, [answer])
    
    return (
        <>
            { answersPossible.map((choice, i) => (
                <Form.Check key={id + i} type="radio" value={choice.value} name={id} id={id+i} label={choice.label}
                    onChange={e => setAnswer(e.target.value)} selected={answer === choice.value} />
            ))}
        </>
    );
}

const TextInputType = (props) => {
    const [answer, setAnswer] = useState('');
    const onAnswerChange = props.onAnswerChange;
    const ref = useRef(null);

    useEffect(() => {
        ref.current.focus();
    })

    useEffect(() => {
        onAnswerChange(answer);
    }, [answer])

    const handleKeyPress= (e) => {
        if(e.key === 'Enter' && !$('.next-btn').is(':disabled')) {
            $('.next-btn').click();
        }
    }

    return (
        <Form.Control
            id={props.id + '-input'}
            type={props.type}
            placeholder="Entre ta réponse ici"
            value={answer}
            ref={ref}
            onChange={e => setAnswer(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e)}
        />
    )
}

const TextAreaType = ({ onAnswerChange, id }) => {
    const [answer, setAnswer] = useState('');
    const ref = useRef(null);
    
    useEffect(() => {
        onAnswerChange(answer);
    }, [answer])

    return (
        <Form.Control id={id + '-textarea'} as="textarea" ref={ref} placeholder="Entre ta réponse ici" value={answer} onChange={e => setAnswer(e.target.value)} />
    );
}

export default Type;
